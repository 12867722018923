import React from "react";
import { useState } from "react";
import { setToken, getProject, removeToken } from "../../utils/utils";

export default function Login({ setAuthenticated }) {
    const [credential, setCredential] = useState('');

    const submit = async (e) => {
        e.preventDefault();
        setToken(credential);
        let error = false;
        await getProject().then(response => {
            if (response === undefined) {
                removeToken();
                alert('Der Server ist nicht erreichbar, bitte versuchen Sie es später erneut.');
                return;
            } else if (response.status > 299) {
                removeToken();
                alert('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
                return;
            } else {
                setAuthenticated(true);
            }
        }).catch(error => {
            removeToken();
            alert('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
            error = true;
            return;
        })
    }
    return (
        <div className='Login'>
            <div className='Chat-header'>Betriebsratsbot</div>
            <input className='Login__input' type='text' placeholder="Authentifizierungstoken..." onChange={(e) => setCredential(e.target.value)} />
            <button className="Login__button" onClick={submit}>Anmelden</button>
        </div>
    )
}
