import React from 'react';
import { HiOutlineChat } from 'react-icons/hi';
import { IoDocumentTextOutline, IoSettingsOutline } from 'react-icons/io5';
import { LuHelpCircle } from 'react-icons/lu';
import { TbMailQuestion } from 'react-icons/tb';
import { HiOutlineLogout } from 'react-icons/hi';
import { Link } from 'react-router-dom';

function Header({logout}) {

    const iconSize = 45;

    return (
        <div className="App-header">
            <Link to="/" className='navbar__item--leading'>
                    <img src='/robot-dark.png' width={60} height={60} alt="Robot" title='Home'/>
            </Link>
            <Link to="/chat" className='navbar__item'>
                <HiOutlineChat size={iconSize} stroke='white' title='Chat'/>
            </Link>
            <Link to="/documents" className='navbar__item'>
                <IoDocumentTextOutline size={iconSize} stroke='white' title='Dokumente'/>
            </Link>
            <Link to="/settings" className='navbar__item'>
                <IoSettingsOutline size={iconSize} stroke='white' title='Einstellungen'/>
            </Link>
            <Link to="/help" className='navbar__item'>
                <LuHelpCircle size={iconSize} stroke='white' title='Häufig gestellte Fragen'/>
            </Link>
            <Link to="/mail" className='navbar__item'>
                <TbMailQuestion size={iconSize} stroke='white' title='Kontakt'/>
            </Link>
            <HiOutlineLogout size={iconSize} className='navbar__item--last logout' stroke='white' onClick={logout} title='Logout'/>
        </div>
    );
}

export default Header;