export default class MessageRequest {
    constructor(prompt, id=null, created_at=null) {
        this.prompt = prompt;
        this.reply = null;
        this.id = id;
        this.createdAt = created_at
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getCreatedAt() {
        return this.createdAt;
    }

    setCreatedAt(created_at) {
        this.createdAt = created_at;
    }

    setReply(reply) {
        this.reply = reply;
    }

    getPrompt() {
        return this.prompt;
    }

    getReply() {
        return this.reply;
    }
}