import React from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import { useState, } from 'react';
import { token, setToken } from '../../utils/utils';

export default function ChatPrompt({setAuthenticated, setRating, setComment, chatFull, setChatFull, setCallToAction, setShowCommentBox, conversation, setConversation, fetchConversations}) {
    const [query, setQuery] = useState("");
  
    const submit = (e) => {
        e.preventDefault();
        if (chatFull) {
            alert('Ich kann keine weiteren Anfragen bearbeiten. Bitte erstelle einen neuen Chat.');
            return;
        }
        if (query === '') {
            alert('Bitte gib eine Frage ein.');
            return;
        }
        setConversation(conversation.addPrompt(query));
        setQuery('');
        setShowCommentBox(false);
        setComment('Dein Feedback ...');
        setRating(0);
        setCallToAction(true);
       
        const json = JSON.stringify({prompt: query, conversation_id: conversation.id})
        const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + process.env.REACT_APP_PROJECT_ID + '/chat';
        axios.post(url, json, {
            headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token(),
            },
            timeout: 60000 // Timeout in milliseconds
        })
        .then(res => {
            if (res.data.token_limit_reached && res.data.token_limit_reached.toString().toLowerCase() == "true") {
                setChatFull(true);
            }
            const newConversation = conversation.setLastReply(res.data.reply);
            const allMessageRequests = newConversation.getMessageRequests();
            const lastMessageRequest = allMessageRequests[allMessageRequests.length - 1];
            lastMessageRequest.setId(res.data.message_request_id);
            setConversation(newConversation);
            fetchConversations();
        })
        .catch(err => {
                    const serverMessage = "Conversation token limit exceeded";
                    if (err.message === serverMessage) {
                        setChatFull(true);
                        setConversation(conversation.setLastReply('Der Chat ist voll. Bitte erstelle einen neuen Chat.'));
                    } else if (err.code === 'ECONNABORTED') {
                        alert('Der Server hat zu lange gebraucht, um zu antworten. Bitte versuche es später erneut.');
                    } else if (err.response.status == 403) {
                        setAuthenticated(false);
                        setToken('')
                        alert('Dein Token ist abgelaufen oder nicht korrekt, bitte logge dich erneut ein')
                    } else {
                        console.log(err);
                    }
        });
    }


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            submit(e);
        }
    }

    return (
        <div className='Chat-Prompt' >
            <TextareaAutosize className='Chat-Prompt__input' value={query} onKeyDown={handleKeyDown} onChange={(e) => setQuery(e.target.value)} placeholder='Frag mich etwas zur Betriebsratsarbeit...'/>
            <button className="arrow-button" onClick={submit}><span className="arrow"></span></button>
        </div>
    )
}